<template>
  <div class="mt-4">
    <div v-if="displayAddStudyButton" class="d-flex flex-row-reverse my-4">
      <base-button type="primary" rounded @click="addStudy">
        {{ $t('study_new') }}
      </base-button>
    </div>
    <StudyList :filterData="filterStudyData" @goToDetail="goToDetail" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StudyList from '@/components/Study/List/StudyList.vue';

export default {
  name: 'QuickStudyListContainerView',
  components: { StudyList },
  computed: {
    ...mapState({
      client: ({ clients }) => clients.selectedClient,
    }),
    displayAddStudyButton() {
      return this.canCreateStudy;
    },
    filterStudyData() {
      return {
        clientId: this.client.basic.id,
      };
    },
  },
  created() {
    this.setBreadCrumbData({
      'client-studies': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.client.basic.legalName,
          to: { name: 'client-details' },
        },
      ],
    });
  },
  methods: {
    addStudy() {
      this.$router.push({
        name: 'create-study',
        params: { clientName: this.client.legalName },
      });
    },
    goToDetail() {
      this.$router.push({ name: 'client-study-details' });
    },
  },
};
</script>
