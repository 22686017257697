<template>
  <div class="bg-white">
    <wizard-header
      title="Create new Study"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard :currentStep="step" wizardType="studies"></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="p-5">
      <basic-information v-show="step === 0" @nextStep="handleNextClick" />
      <study-coordinators
        v-show="step === 1"
        @nextStep="handleRepNext"
        @back="step--"
      />
      <features
        v-show="step === 2"
        @nextStep="handleFeatureNext"
        @back="step--"
      />
      <study-tasks
        v-show="step === 3"
        @back="step--"
        @nextStep="handleTasksNextStep"
      ></study-tasks>
      <overview
        :study="study"
        :studyTasks="studySelectedTasks"
        v-show="step === 4"
        @back="step--"
      />
    </div>

    <!-- Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      title="Create new Study"
      title-tag="h3"
      header-class="justify-content-center"
      footer-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center px-5">
        {{ $t('exit_create_study') }}
      </p>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="
            $router.push({
              name: 'client-studies',
            })
          "
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="cancel()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import WizardHeader from '@/components/WizardHeader';
import BasicInformation from '@/components/Study/BasicInformation';
import StudyCoordinators from '@/components/Study/StudyCoordinators';
import Features from '@/components/Study/Features';
import StudyTasks from '@/components/Study/StudyTasks.vue';
import Overview from '@/components/Study/Overview';
import { mapState } from 'vuex';

export default {
  components: {
    WizardHeader,
    BasicInformation,
    StudyCoordinators,
    Features,
    StudyTasks,
    Overview,
  },
  data() {
    return {
      showConfirmationModal: false,
      step: 0,
      showAddRep: false,
      study: {
        clientId: this.$route.params.id,
        name: '',
        description: '',
        coordinators: [],
        studyFeaturesConfig: {
          mobilePlatformIos: false,
          mobilePlatformAndroid: false,
          videoCalling: false,
          mobileAppNotifications: true,
          quickTour: false,
          messaging: false,
          relapseProtocol: false,
          customParticipantId: false,
          customCode: '',
          language: 'English',
        },
      },
      studySelectedTasks: [],
    };
  },

  computed: {
    ...mapState({
      selectedClient: (state) => state.clients.selectedClient,
    }),
  },

  created() {
    this.setBreadCrumbData({
      'create-study': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient.basic.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.$t('study_create_new'),
          active: true,
        },
      ],
    });
  },
  methods: {
    handleNextClick(payload) {
      this.study = { ...this.study, ...payload };
      this.step++;
    },
    handleRepNext(payload) {
      this.study.coordinators = [...payload];
      this.step++;
    },
    handleFeatureNext(payload) {
      this.study.studyFeaturesConfig = { ...payload };
      this.step++;
    },
    handleTasksNextStep(value) {
      this.studySelectedTasks = value;
      this.step++;
    },
  },
};
</script>

<style></style>
