import { dateToObjectValues, weeksBetweenDates } from '@/utils/dateTransform';

export const participantListDataTableObjectMapper = (participants) => {
  return participants.map((participant) => ({
    participantDisplayId: participant.participantDisplayId,
    subjectCode: participant.subjectCode,
    phone: participant.phone,
    createdAt: participant.createdAt,
    dateStart: participant.dateStart,
    subStatus: participant.subStatus,
    status: participant.status,
    discontinuedReason: participant.discontinuedReason.discontinuedReason,
    clientId: participant.client.id,
    participantId: participant.id,
    protocolId: participant.protocol.id,
    siteId: participant.site.id,
    studyId: participant.study.id,
  }));
};

export const participantDetailStudyScheduleWeeksDataObjectMapper = (
  scheduleData
) => {
  return scheduleData.weeks.map((week) => {
    const { dateStart, dateEnd, tests } = week;
    const numWeek =
      weeksBetweenDates(
        new Date(dateStart),
        new Date(scheduleData.participantStartDateString)
      ) + 1;
    const weekDate = formatWeekDate(dateStart, dateEnd, scheduleData.locale);
    return { dateStart, dateEnd, tests, numWeek, weekDate };
  });
};

const formatWeekDate = (dateStart, dateEnd, locale) => {
  const dateStartValues = dateToObjectValues(normalizedDate(dateStart));
  const dateEndValues = dateToObjectValues(normalizedDate(dateEnd));
  const startMonthName = dateMonthName(dateStart, locale);
  const endMonthName = dateMonthName(dateEnd, locale);
  const differentMonths = dateStartValues.month !== dateEndValues.month;

  const dateStartSegment = `${dateStartValues.date}${
    differentMonths ? ' ' + startMonthName : ''
  }`;
  const dateEndSSegment = `${dateEndValues.date} ${endMonthName}`;

  return `${dateStartSegment} - ${dateEndSSegment}`;
};

const dateMonthName = (dateString, locale) =>
  normalizedDate(dateString).toLocaleDateString(locale, {
    month: 'long',
  });

// "normalized" dateString to avoid date diffs because timezones
const normalizedDate = (dateString) => new Date(dateString.replace('-', '/'));
