<template>
  <div class="bg-white">
    <wizard-header
      title="Update Study"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard :currentStep="step" wizardType="studies"></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="p-5">
      <basic-information v-show="step === 0" @nextStep="handleNextClick" />
      <study-coordinators
        :delete="deleteCoordinators"
        v-show="step === 1"
        @nextStep="handleRepNext"
        @back="step--"
      />
      <features
        v-show="step === 2"
        @nextStep="handleFeatureNext"
        @back="step--"
      />
      <study-tasks
        v-show="step === 3"
        @back="step--"
        @nextStep="handleTasksNextStep"
      ></study-tasks>
      <overview
        :delete="deleteCoordinators"
        :study="study"
        v-if="step === 4"
        @back="step--"
      />
    </div>

    <!-- Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      title="Update Study"
      title-tag="h3"
      header-class="justify-content-center"
      footer-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center px-5">
        {{ $t('exit_update_study') }}
      </p>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="
            $router.push({
              name:
                $route.meta.parent === 'study'
                  ? 'study-details'
                  : `${$route.meta.parent}-study-details`,
            })
          "
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="cancel()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import WizardHeader from '@/components/WizardHeader';
import BasicInformation from '@/components/Study/BasicInformation';
import StudyCoordinators from '@/components/Study/StudyCoordinators';
import Features from '@/components/Study/Features';
import Overview from '@/components/Study/Overview';
import { mapState } from 'vuex';
import StudyTasks from '@/components/Study/StudyTasks.vue';

export default {
  components: {
    StudyTasks,
    WizardHeader,
    BasicInformation,
    StudyCoordinators,
    Features,
    Overview,
  },
  data() {
    return {
      showConfirmationModal: false,
      step: 0,
      showAddRep: false,
      deleteCoordinators: [],
      study: {
        clientId: this.$route.params.id,
        name: '',
        description: '',
        coordinators: [],
        studyFeaturesConfig: {
          mobilePlatformIos: false,
          mobilePlatformAndroid: false,
          videoCalling: false,
          mobileAppNotifications: true,
          quickTour: false,
          messaging: false,
          relapseProtocol: false,
          customParticipantId: false,
          customCode: '',
        },
      },
    };
  },
  created() {
    this.setBreadCrumbData({
      'edit-study': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },
        {
          text: this.selectedStudy.name,
          to: { name: 'study-details' },
        },
        {
          text: this.$t('study_update'),
          active: true,
        },
      ],
      'client-edit-study': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient?.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.selectedStudy.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.$t('study_update'),
          active: true,
        },
      ],
    });
  },
  computed: {
    ...mapState({
      selectedStudy: ({ studies }) => studies.selectedStudy,
      selectedClient: ({ clients }) => clients.selectedClient,
    }),
  },
  mounted() {
    this.study = { ...this.selectedStudy };
  },
  methods: {
    handleNextClick(payload) {
      this.study = { ...this.study, ...payload };
      this.step++;
    },
    handleRepNext(payload) {
      this.study.coordinators = [...payload];
      this.step++;
    },
    handleFeatureNext(payload) {
      this.study.studyFeaturesConfig = { ...payload };
      this.step++;
    },
    handleTasksNextStep() {
      this.step++;
    },
  },
};
</script>
