var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white"},[_c('wizard-header',{attrs:{"title":"Update Study"},on:{"goBack":function($event){_vm.showConfirmationModal = true}}}),_c('div',{staticClass:"bg-white border-bottom"},[_c('b-container',[_c('div',{staticClass:"py-4"},[_c('step-wizard',{attrs:{"currentStep":_vm.step,"wizardType":"studies"}})],1)])],1),_c('div',{staticClass:"p-5"},[_c('basic-information',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 0),expression:"step === 0"}],on:{"nextStep":_vm.handleNextClick}}),_c('study-coordinators',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 1),expression:"step === 1"}],attrs:{"delete":_vm.deleteCoordinators},on:{"nextStep":_vm.handleRepNext,"back":function($event){_vm.step--}}}),_c('features',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 2),expression:"step === 2"}],on:{"nextStep":_vm.handleFeatureNext,"back":function($event){_vm.step--}}}),_c('study-tasks',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 3),expression:"step === 3"}],on:{"back":function($event){_vm.step--},"nextStep":_vm.handleTasksNextStep}}),(_vm.step === 4)?_c('overview',{attrs:{"delete":_vm.deleteCoordinators,"study":_vm.study},on:{"back":function($event){_vm.step--}}}):_vm._e()],1),_c('b-modal',{attrs:{"title":"Update Study","title-tag":"h3","header-class":"justify-content-center","footer-class":"justify-content-center","hide-header-close":"","no-close-on-backdrop":"","centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"rounded-pill",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.push({
            name:
              _vm.$route.meta.parent === 'study'
                ? 'study-details'
                : ((_vm.$route.meta.parent) + "-study-details"),
          })}}},[_vm._v(" "+_vm._s(_vm.$t('yes_cancel'))+" ")]),_c('b-button',{staticClass:"rounded-pill",attrs:{"variant":"primary"},on:{"click":function($event){return cancel()}}},[_vm._v(" "+_vm._s(_vm.$t('no_continue'))+" ")])]}}]),model:{value:(_vm.showConfirmationModal),callback:function ($$v) {_vm.showConfirmationModal=$$v},expression:"showConfirmationModal"}},[_c('p',{staticClass:"text-center px-5"},[_vm._v(" "+_vm._s(_vm.$t('exit_update_study'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }