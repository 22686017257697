<template>
  <div class="container-fluid">
    <b-row>
      <b-col v-if="sent">
        <b-row>
          <b-col>
            <h1>
              {{ $t('link_has_send_header') }}
              <i class="far fa-check-circle ml-2"></i>
            </h1>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col class="description">
            <p>
              {{ $t('link_has_send_detail') }}
            </p>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <router-link
              :to="{ name: 'login' }"
              tag="button"
              class="btn btn-primary rounded-pill ok-btn"
            >
              {{ $t('ok') }}
            </router-link>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-else>
        <b-row>
          <b-col>
            <h1>{{ $t('password_reset') }}</h1>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col class="description opacity-6">
            {{ $t('password_reset_detail') }}
          </b-col>
        </b-row>

        <b-row class="my-5">
          <b-col>
            <label for="email">{{ $t('Email') }}</label>
            <b-form-input
              v-model="email"
              id="email"
              :state="emailStatus"
              aria-describedby="email-live-feedback"
              :placeholder="$t('email_tip')"
            ></b-form-input>
            <b-form-invalid-feedback id="email-live-feedback">
              {{ $t('error_email_not_valid_fe') }}</b-form-invalid-feedback
            >
          </b-col>
        </b-row>

        <b-row v-if="requestError && validEmail" class="mb-3">
          <b-col class="request-error">
            {{ $t(requestError) }}
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="d-flex flex-row justify-content-between align-content-center"
          >
            <div>
              <base-button
                type="primary"
                rounded
                :disabled="disableButton"
                @click="sendReset"
                >{{ $t('send_reset_link') }}
                <b-spinner small v-if="loading"></b-spinner>
              </base-button>
            </div>
            <div class="link">
              <router-link :to="{ name: 'login' }">
                {{ $t('login_back_to') }}
              </router-link>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { passwordReset } from '@/api/auth';

export default {
  name: 'PasswordReset',
  created() {
    let location = window.location;
    let baseUrl = location.protocol + '//' + location.host + '/';
    this.redirectUrl = baseUrl + this.$router.resolve('/password/update').href;
  },
  data() {
    return {
      redirectUrl: '',
      redirectUrlParam: 'hash',
      email: null,
      sent: false,
      loading: false,
      requestError: null,
    };
  },
  computed: {
    disableButton() {
      return !this.validEmail || this.loading;
    },
    validEmail() {
      return (
        !!this.email &&
        /^[a-zA-Z+0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email)
      );
    },
    emailStatus() {
      let value = this.email === null ? null : this.validEmail;

      if(value === false){
        this.editRequestError(null);
      }

      return value;
    },
  },
  methods: {
    editRequestError(value) {
      this.requestError = value;
    },
    async sendReset() {
      this.loading = true;
      this.editRequestError(null);
      try {
        const location = window.location;
        const baseUrl = location.protocol + '//' + location.host;
        await passwordReset({
          email: this.email,
          url: `${baseUrl + this.$router.resolve('/password-update').href}`,
          urlParam: 'hash',
        });
        this.sent = true;
      } catch (error) {
        this.editRequestError( error?.data?.msg || this.$t('error_something_went_wrong'));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.link a {
  font-weight: 500;
  font-size: 16px;
  /* color: #4dc185; */
  cursor: pointer;
}

/* >>> h1 i {
  color: #4dc185;
} */

.request-error {
  font-size: 80%;
  color: #fb6340;
}
.ok-btn {
  width: 163px;
  height: 52px;
  font-size: 1rem;
}
</style>
