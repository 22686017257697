<template>
  <div class="m-5">
    <tabs-navigation v-if="this.showSiteButtons" />
    <router-view></router-view>
  </div>
</template>

<script>
import TabsNavigation from '@/components/TabsNavigation/TabsNavigation.vue';
import { mapState } from 'vuex';
export default {
  components: { TabsNavigation },
  data() {
    return {
      showSiteButtons: false,
    };
  },

  computed: {
    ...mapState({
      showSiteButtonsState: (state) => state.sites.showSiteButtons,
    }),
  },
  watch: {
    showSiteButtonsState(value) {
      this.showSiteButtons = value;
    },
  },
};
</script>
