<template>
  <div class="pt-5 px-5">
    <wizard-header
      title="Create new Role"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard
            :currentStep="step"
            wizardType="userManagement"
          ></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="bg-white p-5">
      <role-information v-show="step === 0" :role="role" @nextStep="step++" />

      <permissions
        v-if="step === 1"
        @nextStep="handleNextClick"
        @back="step--"
        :role="role"
      />

      <overview v-if="step === 2" :role="role" @back="step--" />
    </div>

    <!-- Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      title="Create new Role"
      title-tag="h3"
      header-class="justify-content-center"
      footer-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center px-5">
        {{ $t('exit_create_role') }}
      </p>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="
            $router.push({
              name: 'list-roles',
            })
          "
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="cancel()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import RoleInformation from '@/components/Roles/RoleInformation';
import Permissions from '@/components/Roles/Permissions';
import Overview from '@/components/Roles/Overview';
import WizardHeader from '@/components/WizardHeader';
import { mapActions } from 'vuex';

export default {
  components: {
    WizardHeader,
    RoleInformation,
    Permissions,
    Overview,
  },
  data() {
    return {
      showConfirmationModal: false,
      step: 0,
      role: {
        name: '',
        description: '',
        roleType: '',
        authLevel: '',
        permissions: [],
      },
    };
  },
  created() {
    this.fetchRoleTypes();

    this.setBreadCrumbData({
      'create-role': [
        {
          text: this.$t('user_management'),
          to: { name: 'list-roles' },
        },
        {
          text: this.$t('roles'),
          to: { name: 'list-roles' },
        },

        {
          text: this.$t('role_create'),
          active: true,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      fetchRoleTypes: 'resources/dispatchFetchRoleTypes',
    }),
    handleNextClick(data) {
      this.role.permissions = data.selected;
      this.step++;
    },
  },
};
</script>

<style></style>
