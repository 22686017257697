<template>
  <div :class="$store.getters['auth/canManageUsers'] ? '' : 'mb-4'">
    <base-button
      v-if="$store.getters['auth/canManageUsers']"
      type="primary"
      class="float-right action-button mb-4"
      @click="showModal"
      >{{ $t('team_member_add_short') }}</base-button
    >
    <b-modal
      v-model="modalShow"
      v-if="modalShow"
      hide-header-close
      hide-footer
      no-close-on-backdrop
      size="lg"
      title-tag="h2"
    >
      <template slot="modal-title">{{ $t('team_member_add') }} </template>

      <b-tabs
        pills
        nav-class="theme-pills"
        active-nav-item-class="rounded-pill"
        nav-wrapper-class="clearfix"
        class="px-3"
        content-class="mt-3"
        v-model="currentTab"
        @input="resetForm"
      >
        <b-tab title="Current user">
          <b-row>
            <b-col cols="6">
              <label for="">{{ $t('site_select') }}</label>
              <v-select
                v-model="currentSiteId"
                :options="sites"
                :reduce="(site) => site.id"
                :custom-label="({ site }) => `${site.name}`"
                :disabled="isSiteView"
              />
            </b-col>
            <b-col v-if="currentSiteId">
              <label for="">{{ $t('user_select_a') }}</label>
              <v-select
                v-model="existingUser"
                :options="filteredUsersProfiles"
                :reduce="(user) => user.id"
                :custom-label="
                  ({ user }) => `${user.firstName} ${user.lastName}`
                "
                label="fullName"
                @input="changeCurrentUser"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="New user">
          <div v-if="canManageUsers" class="mb-3">
            <label>{{ $t('user_status') }}</label>
            <b-form-checkbox
              id="assign_user_status"
              name="assign_user_status"
              switch
              v-model="userIsActive"
            >
              {{ userIsActive ? $t('ACTIVE') : $t('INACTIVE') }}
            </b-form-checkbox>
          </div>

          <div class="mb-3">
            <label>{{ $t('site_select_a') }}</label>
            <v-select
              v-model="currentSiteId"
              :options="sites"
              :reduce="(site) => site.id"
              :custom-label="({ site }) => `${site.name}`"
              :disabled="isSiteView"
            />
          </div>

          <b-row>
            <b-col cols="6">
              <label>{{ $t('name_first') }}</label>
              <b-form-input
                v-model="member.firstName"
                :placeholder="$t('name_first')"
              ></b-form-input>
            </b-col>
            <b-col cols="6">
              <label>{{ $t('name_last') }}</label>
              <b-form-input
                v-model="member.lastName"
                :placeholder="$t('name_last')"
              ></b-form-input>
            </b-col>
          </b-row>

          <label>{{ $t('title_work_job') }}</label>
          <b-form-input
            v-model="member.title"
            :placeholder="$t('title_work_job')"
          ></b-form-input>

          <label>{{ $t('email') }}</label>
          <b-form-input
            v-model="member.email"
            id="email"
            :state="emailStatus"
            :placeholder="$t('email_tip')"
            aria-describedby="email-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="email-live-feedback">{{
            $t('error_email_not_valid_fe')
          }}</b-form-invalid-feedback>

          <label>{{ $t('phone') }}</label>
          <phone-number-input-by-country v-model="member.phone" />
        </b-tab>
      </b-tabs>
      <div class="px-3">
        <label for="">{{ $t('roles') }}</label>
        <b-form-checkbox-group
          :options="roles"
          v-model="selected"
          class="mb-5 half clearfix"
          text-field="name"
          value-field="id"
          stacked
        />

        <base-button
          type="button"
          class="ml-auto"
          @click="modalShow = false"
          :disabled="loading"
          >{{ $t('cancel') }}
        </base-button>
        <base-button
          type="primary"
          rounded
          :disabled="loading || !isValidRep"
          @click="handleAddMember"
          >{{ $t('add') }} <b-spinner v-if="loading" small></b-spinner
        ></base-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { validEmail } from '@/utils/validations';
import { postTeamMember } from '@/api/members';
import { createExpert } from '@/api/experts';
import PhoneNumberInputByCountry from '@/components/PhoneNumberInput';

export default {
  components: {
    PhoneNumberInputByCountry,
  },
  data() {
    return {
      userIsActive: true,
      modalShow: false,
      existingUser: '',
      currentSiteId: '',
      currentTab: 0,
      loading: false,
      member: {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        phone: '',
      },
      selected: [],
      isSiteView: this.$route.name.includes('site'),
    };
  },
  created() {
    this.setBreadCrumbData({
      'client-list-team': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study.name,
          active: true,
        },
      ],
      'study-list-team': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },

        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
      ],
      'study-site-list-team': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },

        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.selectedSite.basic?.name,
          active: true,
        },
      ],
      'site-list-team': [
        {
          text: this.$t('sites'),
          to: { name: 'sites' },
        },

        {
          text: this.selectedSite.basic?.name,
          active: true,
        },
      ],
    });
    this.getSites();
  },
  mounted() {
    if (this.isSiteView) {
      this.currentSiteId = this.selectedSite.basic.id;
    }
  },
  watch: {
    userIsActive(newValue) {
      this.member.status = newValue ? 'active' : 'inactive';
    },
    modalShow(newValue) {
      if (!newValue) this.resetForm();
    },
  },
  computed: {
    ...mapState({
      selectedClient: (state) => state.clients.selectedClient,
      study: ({ studies }) => studies.selectedStudy,
      roles: ({ resources }) => resources.roles,
      sites: ({ studies }) =>
        studies.sites.map(({ site }) => ({ id: site.id, label: site.name })),
      selectedSite: ({ sites }) => sites.selectedSite,
      noRoleUsers: ({ experts }) => experts.noRoleUsers,
    }),
    ...mapGetters({
      canManageUsers: 'auth/canManageUsers',
      expertStatusFromId: 'experts/expertStatusFromId',
    }),
    filteredUsersProfiles() {
      return this.noRoleUsers.filter((noRoleUser) => {
        return !noRoleUser.profiles.some(
          (u) =>
            u.profile.code === 'SITE_MEMBER' &&
            u.resourceId === this.currentSiteId
        );
      });
    },
    emailStatus() {
      return this.member.email === '' ? null : validEmail(this.member.email);
    },
    isValidRep() {
      const { firstName, lastName, email, title, phone } = this.member;
      switch (this.currentTab) {
        case 0:
          return (
            !!this.existingUser &&
            !!this.currentSiteId &&
            this.selected.length > 0
          );

        case 1:
          return (
            this.selected.length > 0 &&
            !!firstName &&
            !!lastName &&
            !!email &&
            !!title &&
            !!phone &&
            this.currentSiteId
          );
        default:
          return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchSites: 'studies/dispatchFetchSites',
      fetchRoles: 'resources/dispatchFetchRoles',
      fetchNoRoleExperts: 'experts/dispatchAuthLevelUsers',
      setBreadcrumb: 'breadcrumb/set',
    }),
    showModal() {
      this.fetchResources();
      this.modalShow = true;
    },
    fetchResources() {
      this.fetchRoles({ 'filter[auth_level]': 'site' });
      this.fetchNoRoleExperts({
        'filter[type]': 'client',
        'filter[client_id]': this.study.clientId,
      });
    },
    resetForm() {
      Object.keys(this.member).map((key) => (this.member[key] = ''));
      this.selected = [];
      this.existingUser = '';
      !this.isSiteView && (this.currentSiteId = '');
    },
    changeCurrentUser(id) {
      if (id) {
        const {
          user: { firstName, lastName, phone, email, title },
        } = this.noRoleUsers.filter((i) => i.id === id)[0];
        this.member = { firstName, lastName, phone, email, title, id };
      } else {
        this.selected = [];
      }
    },
    async handleAddMember() {
      try {
        this.loading = true;

        const urlParams = {
          clientId: this.study.clientId,
          studyId: this.study.id,
          protocolId: this.selectedSite.basic.protocolId,
          siteId: this.currentSiteId,
        };

        if (this.currentTab === 0) {
          await this.submitUserRoles(this.existingUser, urlParams);
        } else {
          const {
            data: { id: expertId },
          } = await createExpert({
            ...this.member,
            clientId: urlParams.clientId,
          });

          if (expertId) {
            await this.submitUserRoles(expertId, urlParams);
          } else {
            this.$toast.error(this.$t('error_something_went_wrong'));
          }
        }
      } catch (error) {
        this.displayError('Error add team member', error);
      } finally {
        this.loading = false;
      }
    },
    async submitUserRoles(expertId, urlParams) {
      const { data } = await postTeamMember(urlParams, {
        expertId,
        roles: this.selected.map((id) => ({ id })),
      });
      if (data.id) {
        this.$emit('refreshList');
        this.modalShow = false;
        this.$toast.info(this.$t('toast_team_member_added'));
      }
    },
    async getSites() {
      try {
        const payload = {
          'filter[client_id]': this.study.clientId,
          'filter[study_id]': this.study.id,
        };
        await this.fetchSites(payload);
      } catch (error) {
        this.displayError('Error get sites (create team member)', error);
      }
    },
  },
};
</script>

<style>
.half .custom-checkbox {
  width: 50%;
  float: left;
}
</style>
