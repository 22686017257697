<template>
  <v-client-table
    :data="participantListTableData"
    :columns="columns"
    :options="options"
    :class="tableClass"
    class="participant-table"
    ref="participantsTable"
    @row-click="goToDetail"
    @sorted="handleSorted"
  >
    <template slot="participantDisplayId" slot-scope="props">
      <span>{{ props.row.participantDisplayId }}</span
      ><span
        v-if="showHighlightTag && props.row.id === $route.params.participantId"
        class="highlight-tag"
        >{{ highlightTagText }}</span
      >
    </template>
    <template slot="createdAt" slot-scope="props">
      {{ moment.unix(props.row.createdAt).format('DD/MM/YYYY') }}
    </template>
    <template slot="dateStart" slot-scope="props">
      {{
        props.row.dateStart
          ? moment(props.row.dateStart).format('DD/MM/YYYY')
          : 'N/A'
      }}
    </template>
    <template slot="subStatus" slot-scope="props">
      <WebBadgeComponent :status="props.row.subStatus" />
    </template>
    <template slot="status" slot-scope="props">
      <WebBadgeComponent :status="props.row.status" />
    </template>
  </v-client-table>
</template>

<script>
import listHighLightMixin from '@/mixins/listHighLightMixin';
import WebBadgeComponent from '@/shared/components/WebBadgeComponent.vue';
import { participantStatusId } from '@/constants/participantStatuses';

export default {
  components: { WebBadgeComponent },
  props: {
    participantListTableData: Array,
    orderBy: {
      type: Object,
      default: () => ({
        column: 'createdAt',
        ascending: false,
      }),
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  mixins: [listHighLightMixin],
  data() {
    return {
      participantStatusId,
      columns: [
        'participantDisplayId',
        'subjectCode',
        'phone',
        'createdAt',
        'dateStart',
        'subStatus',
        'status',
      ],
      options: {
        headings: {
          participantDisplayId: this.$t('participant_id'),
          subjectCode: this.$t('participant_id_subject'),
          phone: this.$t('phone'),
          createdAt: this.$t('date_creation'),
          dateStart: this.$t('date_start'),
          subStatus: this.$t('alert'),
          status: this.$t('status'),
        },
        sortable: [
          'participantDisplayId',
          'createdAt',
          'dateStart',
          'subStatus',
        ],
        orderBy: this.orderBy,
        perPage: 9999,
      },
    };
  },
  methods: {
    goToDetail({ row }) {
      if (!this.isLoading) this.$emit('goToDetail', row);
    },
    handleSorted({ column, ascending }) {
      this.$refs.participantsTable.setOrder(column, ascending);
      this.$emit('sorting', column, ascending);
    },
  },
};
</script>

<style scoped>
.VueTables table.app-table tr {
  cursor: pointer;
}
</style>
