<template>
  <div>
    <h2 class="">{{ $t('permissions') }}</h2>

    <b-form-checkbox-group stacked :options="options" v-model="selected" />
    <b-spinner class="mx-auto d-block" v-if="loading"></b-spinner>

    <b-row v-if="isEdit">
      <b-col>
        <hr />
        <p class="avenir-bold">
          {{ $t('role_changes_take_effect') }}
        </p>
        <b-form-radio-group
          v-model="logOut"
          stacked
          data-testid="logout-options-inputs"
        >
          <b-form-radio value="immediately" data-testid="immediately-value">
            <span> {{ $t('role_changes_take_effect_immediately') }}</span>
          </b-form-radio>
          <b-form-radio
            class="mt-2"
            value="next_login"
            data-testid="next-login-value"
          >
            <span>{{ $t('role_changes_take_effect_next_login') }}</span>
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          :disabled="selected.length === 0"
          @click="$emit('nextStep', { selected, logOut })"
          data-testid="next-button"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    role: Object,
  },
  data() {
    return {
      options: [],
      selected: this.role.permissions,
      isEdit: this.$route.name === 'edit-role',
      logOut: 'immediately',
      loading: true,
    };
  },
  computed: {
    ...mapState({
      permissions: ({ resources }) => resources.permissions,
    }),
    ...mapGetters({
      selectedRole: 'roles/displayedRoleFields',
    }),
  },

  created() {
    this.$store
      .dispatch(
        'resources/dispatchFetchPermissions',
        this.role.roleType === 'Client'
      )
      .finally(() => {
        this.loading = false;
        this.refactorPermissionSet();
      });
  },

  methods: {
    refactorPermissionSet() {
      this.options = this.permissions.map((item) => ({
        text: item.name,
        value: { id: item.id },
      }));
    },
  },
};
</script>

<style></style>
