<template>
  <div>
    <wizard-header
      title="Create new Study Protocol"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <div class="p-4">
        <step-wizard :currentStep="step" wizardType="protocol"></step-wizard>
      </div>
    </div>

    <div class="bg-white p-5">
      <basic-information
        v-show="step === 0"
        @nextStep="handleNextClick"
        :basic="protocol.basic"
      />
      <protocolApprover
        v-show="step === 1"
        @nextStep="handleRepNextClick"
        @back="step--"
      />
      <schedule
        v-show="step === 2"
        @nextStep="handleScheduleNextClick"
        @back="step--"
      />
      <external
        v-show="step === 3"
        @nextStep="handleNextClick"
        @back="step--"
      />
      <overview
        :protocol="protocol"
        v-if="step === 4"
        @back="step--"
        :submitting="submitting"
        @createProtocol="postProtocol"
      />
    </div>

    <!-- Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      centered
      title="Create new Study Protocol"
      footer-class="justify-content-center"
      hide-header-close
      header-class="justify-content-center"
      title-tag="h3"
    >
      <p class="text-center px-5">
        {{ $t('exit_create_protocol') }}
      </p>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="
            $router.push({
              name: `${$route.meta.parent}-protocols`,
            })
          "
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="cancel()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  createProtocol,
  createApprover,
  createIntegrations,
} from '@/api/protocol';
import { createExpert } from '@/api/experts';

import BasicInformation from '@/components/Protocol/BasicInformation';
import ProtocolApprover from '@/components/Protocol/ProtocolApprover.vue';
import Schedule from '@/components/Protocol/Schedule';
import External from '@/components/Protocol/External';
import Overview from '@/components/Protocol/Overview';
import WizardHeader from '@/components/WizardHeader.vue';
import { mapState } from 'vuex';

export default {
  components: {
    BasicInformation,
    Overview,
    ProtocolApprover,
    Schedule,
    External,
    WizardHeader,
  },
  data() {
    return {
      showConfirmationModal: false,
      submitting: false,
      step: 0,
      protocol: {
        basic: {
          name: '',
          description: '',
          studyDurationPerParticipant: '',
          plannedNumberOfParticipants: '',
        },
        approver: [],
        tests: {},
      },
    };
  },
  computed: {
    ...mapState({
      selectedClient: (state) => state.clients.selectedClient,
      study: (state) => state.studies.selectedStudy,
    }),
  },
  created() {
    this.setBreadCrumbData({
      'client-create-protocol': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.$t('protocol_create_new'),
          active: true,
        },
      ],

      'study-create-protocol': [
        {
          text: this.$t('studies'),
          to: { name: 'clients' },
        },
        {
          text: this.study.name,
          to: { name: 'study-details' },
        },
        {
          text: this.$t('protocol_create_new'),
          active: true,
        },
      ],
    });
  },
  methods: {
    handleNextClick(payload) {
      if (payload) {
        this.protocol = { ...this.protocol, ...payload };
      }
      this.step++;
    },
    handleRepNextClick(approver) {
      this.protocol.approver = approver;
      this.step++;
    },
    handleScheduleNextClick() {
      this.step++;
    },
    handleTestGamesNextClick(weekLength, games) {
      this.protocol.tests = {
        weekLength,
        games,
      };
      this.step++;
    },
    handleSurveysNextClick(surveys) {
      this.protocol.tests.surveys = [...surveys];
      this.step++;
    },
    async addProtocolApprover(protocolId, rep) {
      try {
        const { roles, ...info } = rep;
        info.clientId = this.study.clientId;
        const {
          data: { id: expertId },
        } = await createExpert(info);

        if (expertId) {
          const rolesPayload = {
            expertId,
            roles: roles.map((id) => ({ id })),
          };
          await createApprover(
            this.study.clientId,
            this.study.id,
            protocolId,
            rolesPayload
          );
        } else {
          console.log("expert id doesn't exist");
        }
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
    async postProtocol() {
      try {
        this.submitting = true;
        const { clientId, id: studyId } = this.study;
        const basic = { ...this.protocol.basic };

        if (!basic.description.trim()) delete basic.description;

        const {
          data: { id: protocolId },
        } = await createProtocol({
          clientId,
          studyId,
          payload: basic,
        });

        if (protocolId) {
          await this.completeProtocolSubmit(protocolId);
        } else {
          throw Error('Error create protocol: no protocol id');
        }

        this.$toast.info(this.$t('toast_protocol_new_created'));
        this.$router.push({
          name: `${this.$route.meta.parent}-protocols`,
          params: { isNewlyCreated: true, protocolId },
        });
      } catch (error) {
        this.displayError('Error create protocol', error);
      } finally {
        this.submitting = false;
      }
    },
    async completeProtocolSubmit(protocolId) {
      // Add approver
      const approver = this.protocol.approver[0];
      const { roles, ...user } = approver;
      const { clientId, id: studyId } = this.study;
      let expertId;

      if (approver?.type === 'new') {
        const { data } = await createExpert({
          ...user,
          clientId,
        });
        expertId = data.id;
      } else if (user.expertId) {
        expertId = user.expertId;
      }

      if (!expertId) {
        console.error('error create approver, no expert id');
        throw new Error();
      }

      const payload = {
        expertId,
        roles: roles.map((id) => ({ id })),
      };
      await createApprover(clientId, studyId, protocolId, payload);

      // Add integrations
      const dataSources = [];
      if (this.protocol.googleFit) dataSources.push({ id: 1 });
      if (this.protocol.appleHealth) dataSources.push({ id: 2 });
      if (this.protocol.fitbit) dataSources.push({ id: 3 });

      if (dataSources.length) {
        await createIntegrations(
          { clientId, studyId, protocolId },
          { dataSources }
        );
      }
    },
  },
};
</script>

<style></style>
