<template>
  <div class="text-center my-4" v-if="loading">
    <b-spinner></b-spinner>
  </div>
  <div v-else>
    <div class="text-right mb-4 clearfix" v-if="showUpdateButton">
      <router-link :to="{ name: 'edit-client' }">
        <base-button type="primary" class="float-right action-button" v>
          Update Client</base-button
        >
      </router-link>
    </div>
    <overview
      v-if="canReadClient"
      :client="selectedClient"
      :class="!showUpdateButton && 'mt-4'"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Overview from '@/components/Clients/Overview';

export default {
  components: {
    Overview,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      selectedClient: (state) => state.clients.selectedClient,
    }),

    showUpdateButton() {
      return (
        this.canCreateClient && this.selectedClient.basic.status !== 'INACTIVE'
      );
    },
  },
  created() {
    this.$store.dispatch('experts/dispatchFetchManagers');
    this.fetchBasicInfo();
  },
  methods: {
    ...mapActions({
      setBreadcrumb: 'breadcrumb/set',
    }),
    fetchBasicInfo() {
      this.$store
        .dispatch('clients/dispatchFetchClient', this.$route.params.id)
        .finally(() => {
          this.loading = false;
          this.setBreadCrumbData({
            'client-details': [
              {
                text: this.$t('clients'),
                to: { name: 'clients' },
              },
              {
                text: this.selectedClient.basic.legalName,
                to: { name: 'client-details' },
              },
            ],
          });
        });
    },
  },
};
</script>
