<template>
  <div class="m-5">
    <div>
      <div class="text-right clearfix">
        <router-link :to="{ name: 'edit-role' }">
          <base-button type="primary" class="float-right mb-4">
            {{ $t('role_update') }}</base-button
          >
        </router-link>
      </div>
      <overview :role="selectedRole" />
    </div>
  </div>
</template>

<script>
import Overview from '@/components/Roles/Overview';
import { mapGetters } from 'vuex';

export default {
  components: {
    Overview,
  },

  created() {
    this.setBreadCrumbData({
      'view-role': [
        {
          text: this.$t('user_management'),
          to: { name: 'list-roles' },
        },
        {
          text: this.$t('roles'),
          to: { name: 'list-roles' },
        },
        {
          text: this.selectedRole.name,
          active: true,
        },
      ],
    });
  },
  computed: {
    ...mapGetters({
      selectedRole: 'roles/displayedRoleFields',
    }),
  },
};
</script>

<style></style>
