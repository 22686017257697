<template>
  <div class="m-5">
    <tabs-navigation />
    <router-view></router-view>
  </div>
</template>

<script>
import TabsNavigation from '@/components/TabsNavigation/TabsNavigation.vue';
export default {
  components: { TabsNavigation },
};
</script>
