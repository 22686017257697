<template>
  <div>
    <h2 class="mb-5" v-if="!isView">{{ $t('overview') }}</h2>

    <div class="overview-card">
      <p class="text-lg">{{ $t('role_information') }}</p>
      <b-row>
        <b-col>
          <p class="opacity-6 mb-0">{{ $t('role_type') }}</p>
          <p class="avenir-bold text-break">{{ role.roleType }}</p>
        </b-col>
        <b-col>
          <p class="opacity-6 mb-0">{{ $t('role_auth_level') }}</p>
          <p class="avenir-bold text-break">{{ role.authLevel }}</p>
        </b-col>
        <b-col>
          <p class="opacity-6 mb-0">{{ $t('role_name') }}</p>
          <p class="avenir-bold text-break">{{ role.name }}</p>
        </b-col>
      </b-row>
      <b-row v-if="role.description">
        <b-col>
          <p class="mb-0 opacity-6">{{ $t('description') }}</p>
          <b-row
            ><p class="mb-0 col avenir-bold">
              {{ role.description }}
            </p>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <div class="overview-card">
      <p class="text-lg">{{ $t('permissions') }}</p>
      <b-row>
        <b-col
          cols="4"
          class="mb-2 font-weight-600"
          v-for="permission in selectedPermissions"
          :key="permission.id"
        >
          <i class="ni ni-check-bold text-primary"></i> {{ permission.name }}
        </b-col>
      </b-row>
    </div>
    <div class="overview-card" v-if="isEdit">
      <p class="text-lg w-75">
        {{ $t('role_changes_take_effect') }}
      </p>
      <b-row>
        <b-col v-if="role.logOut === 'immediately'">
          <span class="opacity-6 font-weight-400 ml-1">
            {{ $t('role_changes_take_effect_immediately') }}</span
          >
        </b-col>
        <b-col v-else>
          <span class="opacity-6 font-weight-400 ml-1">{{
            $t('role_changes_take_effect_next_login')
          }}</span>
        </b-col>
      </b-row>
    </div>

    <b-row class="my-5" v-if="!isView">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          :disabled="loading"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="isEdit ? updateRole() : postRole()"
          :disabled="loading"
          data-testid="submit-button"
        >
          {{ isEdit ? 'Update Role' : 'Create Role' }}
          <b-spinner small v-if="loading"></b-spinner>
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { createRole, updateRole } from '@/api/roles';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      isEdit: this.$route.name === 'edit-role',
      isView: this.$route.name === 'view-role',
    };
  },
  props: {
    role: Object,
  },
  computed: {
    ...mapState({
      permissions: ({ resources }) => resources.permissions,
    }),

    selectedPermissions() {
      return this.isView
        ? this.role.permissions
        : this.permissions.filter(
            (permission) =>
              this.role.permissions.findIndex(
                (perm) => perm.id === permission.id
              ) !== -1
          );
    },
  },
  methods: {
    async postRole() {
      this.loading = true;
      try {
        const payload = { ...this.role };
        if (!payload.description.trim()) delete payload.description;

        const { data } = await createRole(payload);

        data.id
          ? (this.$toast.info(this.$t('toast_role_new_created')),
            this.$router.push({
              name: 'list-roles',
              params: { isNewlyCreated: true, roleId: data.id },
            }))
          : this.$toast.error(this.$t('error_something_went_wrong'));
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.loading = false;
      }
    },

    async updateRole() {
      this.loading = true;
      try {
        const payload = { ...this.role };
        delete payload.authLevel;
        delete payload.roleType;
        delete payload.id;

        //handle the case when there is a previous description and the use remove it
        if (
          payload.description !== null &&
          payload.description.trim().length === 0
        )
          payload.description = null;

        await updateRole(this.role.id, payload);
        this.$toast.info(this.$t('toast_role_updated'));
        if (this.role.logOut === 'immediately') {
          this.$toast.info(this.$t('toast_session_expired'));
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.push(`/login`);
          });
        } else {
          this.$router.push({ name: 'list-roles' });
        }
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
