<template>
  <div>
    <div class="text-center" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <div class="text-right clearfix action-button" v-if="canCreateStudy">
        <router-link
          :to="{ name: isClientView ? 'client-edit-study' : 'edit-study' }"
        >
          <base-button type="primary" class="float-right mb-4">
            {{ $t('study_update') }}</base-button
          >
        </router-link>
      </div>
      <overview :study="study" :class="!canCreateStudy && 'mt-4'" />
    </div>
  </div>
</template>

<script>
import { getStudyDetails, getStudyCoordinators } from '@/api/study';
import { mapState } from 'vuex';
import Overview from '@/components/Study/Overview';
import { fetchExperts } from '@/api/experts';

export default {
  components: {
    Overview,
  },

  data() {
    return {
      loading: true,
      studies: [],
      isClientView: this.$route.name === 'client-study-details',
    };
  },
  computed: {
    ...mapState({
      study: ({ studies }) => studies.selectedStudy,
      selectedClient: ({ clients }) => clients.selectedClient,
      studyParams: ({ studies }) => studies.studyParams,
    }),
  },
  created() {
    if (!this.isClientUser)
      this.$store.dispatch('experts/dispatchFetchManagers');

    this.fetchStudyDetails();
  },
  methods: {
    async fetchStudyDetails() {
      try {
        const { studyId, clientId } = this.studyParams;
        const payload = { studyId, clientId };
        const [
          { data: studyDetails },
          { data: coordinators },
          { data: experts },
        ] = await Promise.all([
          getStudyDetails(payload),
          getStudyCoordinators(payload),
          fetchExperts({ [`filter[client_id]`]: clientId }),
        ]);

        coordinators.forEach((coordinator) => {
          const expert = experts.find((expert) => {
            return expert.id === coordinator.expertId;
          });
          if (expert?.user?.status?.name?.length) {
            coordinator.status = { ...expert.user.status };
          }
        });

        this.$store.commit('studies/SELECTED_STUDY', {
          ...studyDetails,
          coordinators,
          studyFeaturesConfig: {
            ...studyDetails.studyFeaturesConfig,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        // Set breadcrumbs
        this.setBreadCrumbData({
          'client-study-details': [
            {
              text: this.$t('clients'),
              to: { name: 'clients' },
            },
            {
              text: this.selectedClient.basic?.legalName,
              to: { name: 'client-details' },
            },
            {
              text: this.study.name,
              active: true,
            },
          ],
          'study-details': [
            {
              text: this.$t('studies'),
              to: { name: 'studies' },
            },

            {
              text: this.study.name,
              active: true,
            },
          ],
        });
      }
    },
  },
};
</script>

<style></style>
