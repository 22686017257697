<template>
  <div>
    <h2 class="">{{ $t('role_information') }}</h2>
    <b-row>
      <b-col>
        <label for="">{{ $t('role_type') }}</label>
        <v-select
          class=""
          v-model="role.roleType"
          :placeholder="$t('role_type_tip')"
          :options="roleTypes"
          label="name"
          :reduce="(type) => type.name"
          :disabled="roleTypes.length === 0 || isEdit"
          @input="handleRoleTypeChange"
          data-testid="role-type"
        />
      </b-col>
      <b-col>
        <label for="">{{ $t('role_auth_level') }}</label>
        <v-select
          class=""
          v-model="role.authLevel"
          :placeholder="authLevelText"
          :options="authLevels"
          label="displayName"
          :reduce="(authLevel) => authLevel.displayName"
          :disabled="authLevels.length === 0 || isEdit || isHealiosRole"
          data-testid="auth-level"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <label>{{ $t('role_name') }}</label>
        <b-form-input
          v-model="role.name"
          maxLength="100"
          placeholder="Role Name"
          data-testid="roleName-input"
        ></b-form-input>
      </b-col>
      <b-col cols="12">
        <label>{{ $t('description') }}</label>
        <textarea
          rows="4"
          maxlength="400"
          v-model="role.description"
          class="form-control form-control"
          placeholder="Description and notes"
          data-testid="description-input"
        />
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <base-button
          type="primary"
          rounded
          @click="shouldValidate ? handleNextClick() : $emit('nextStep')"
          :disabled="!isValid || loading"
          data-testid="next-button"
        >
          {{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { fetchAuthLevels } from '@/api/resources';
import { fetchRoles } from '@/api/roles';

export default {
  data() {
    return {
      loading: false,
      authLevels: [],
      isEdit: this.$route.name === 'edit-role',
      isHealiosRole: false,
      authLevelText: this.$t('role_auth_level_short_tip'),
    };
  },
  props: {
    role: {
      name: String,
      description: String,
      roleType: String,
      authLevel: String,
    },
  },
  watch: {
    role: {
      handler() {
        if (this.role.roleType === 'Healios') {
          this.authLevelText = this.$t('system');
          this.isHealiosRole = true;
          this.role.authLevel = 'System';
        } else {
          this.authLevelText = this.$t('role_auth_level_short_tip');
          this.isHealiosRole = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      roleTypes: (state) => state.resources.roleTypes,
      selectedRole: (state) => state.roles.selectedRole,
    }),

    isValid() {
      const { name, authLevel, roleType } = this.role;
      return !!name && !!authLevel && !!roleType;
    },

    shouldValidate() {
      return !(
        this.$route.name === 'edit-role' &&
        this.role.name === this.selectedRole.name
      );
    },
  },
  methods: {
    async handleRoleTypeChange(type) {
      this.role.authLevel = '';
      if (type) {
        this.authLevel = [];
        try {
          const { data } = await fetchAuthLevels(type);
          this.authLevels = [...data];
        } catch (error) {
          this.$toast.error(
            this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
          );
        }
      }
    },

    async handleNextClick() {
      this.loading = true;
      try {
        const { data } = await fetchRoles({
          'filter[name]': this.role.name.trim(),
        });
        data.length === 0
          ? this.$emit('nextStep')
          : this.$toast.error(this.$t('error_role_name_in_use'));
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
