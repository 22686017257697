<template>
  <route-tabs v-if="fetchRoutes.length > 0">
    <route-tab
      v-for="route in fetchRoutes"
      :link="{ name: route.pathName }"
      :key="route.pathName"
    >
      {{ route.title }}
    </route-tab>
  </route-tabs>
</template>

<script>
export default {
  data() {
    return {
      client: [
        { pathName: 'client-details', title: 'Basic info' },
        { pathName: 'client-studies', title: 'studies' },
        // { pathName: "users", title: "users" },
      ],
      clientStudy: [
        { pathName: 'client-study-details', title: 'Basic info' },
        { pathName: 'client-protocols', title: 'Protocols' },
        { pathName: 'client-sites', title: 'Sites' },
        { pathName: 'client-list-team', title: 'Team Members' },
        { pathName: 'client-participants', title: 'Participants' },
      ],
      clientSite: [
        { pathName: 'client-view-site', title: 'Basic info' },
        { pathName: 'client-site-list-team', title: 'Team Members' },
        { pathName: 'client-site-participants', title: 'Participants' },
      ],
      clientProtocol: [
        { pathName: 'client-view-protocol', title: 'Basic info' },
        { pathName: 'client-protocol-sites', title: 'Sites' },
        { pathName: 'client-protocol-participants', title: 'Participants' },
      ],
      study: [
        { pathName: 'study-details', title: 'Basic info' },
        { pathName: 'study-protocols', title: 'Protocols' },
        { pathName: 'study-sites', title: 'Sites' },
      ],
      studySite: [
        { pathName: 'study-view-site', title: 'Basic info' },
        { pathName: 'study-site-list-team', title: 'Team Members' },
        { pathName: 'study-site-participants', title: 'Participants' },
      ],
      studyProtocol: [
        { pathName: 'study-view-protocol', title: 'Basic info' },
        { pathName: 'study-protocol-sites', title: 'Sites' },
        { pathName: 'study-protocol-participants', title: 'Participants' },
      ],
      protocol: [
        { pathName: 'view-protocol', title: 'Basic info' },
        { pathName: 'protocol-sites', title: 'Sites' },
      ],
      protocolSite: [
        { pathName: 'protocol-view-site', title: 'Basic info' },
        { pathName: 'protocol-site-list-team', title: 'Team Members' },
        { pathName: 'protocol-site-participants', title: 'Participants' },
      ],
      site: [
        { pathName: 'view-site', title: 'Basic info' },
        { pathName: 'site-list-team', title: 'Team Members' },
        { pathName: 'site-participants', title: 'Participants' },
      ],
    };
  },
  computed: {
    fetchRoutes() {
      switch (this.$route.name) {
        //Client
        case 'client-details':
        case 'client-studies':
          return this.client;

        case 'client-study-details':
        case 'client-protocols':
        case 'client-sites':
        case 'client-list-team':
        case 'client-participants':
          return this.clientStudy;

        case 'client-view-site':
        case 'client-site-list-team':
        case 'client-site-participants':
          return this.clientSite;

        case 'client-view-protocol':
        case 'client-protocol-sites':
        case 'client-protocol-participants':
          return this.clientProtocol;

        //Study
        case 'study-details':
        case 'study-protocols':
        case 'study-sites':
        case 'study-list-team':
        case 'study-participants':
          return this.study;

        case 'study-view-site':
        case 'study-site-list-team':
        case 'study-site-participants':
          return this.studySite;

        case 'study-view-protocol':
        case 'study-protocol-sites':
        case 'study-protocol-participants':
          return this.studyProtocol;

        //protocol
        case 'view-protocol':
        case 'protocol-sites':
        case 'protocol-participants':
          return this.protocol;

        case 'protocol-view-site':
        case 'protocol-site-list-team':
        case 'protocol-site-participants':
          return this.protocolSite;

        //site
        case 'view-site':
        case 'site-list-team':
        case 'site-participants':
          return this.site;
        default:
          return [];
      }
    },
  },
};
</script>

<style></style>
