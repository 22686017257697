<template>
  <div class="m-5">
    <router-link
      :to="{
        name: 'create-role',
      }"
    >
      <base-button type="primary" class="float-right mb-4">{{
        $t('role_new')
      }}</base-button>
    </router-link>
    <div class="text-center" v-if="loading"><b-spinner></b-spinner></div>
    <v-client-table
      :data="roles"
      :columns="columns"
      :options="options"
      :class="tableClass"
      v-else
    >
      <template slot="roleType" slot-scope="props">
        <span v-if="props.row.roleType">
          {{ props.row.roleType.name }}
        </span>
        <span
          v-if="showHighlightTag && props.row.id === $route.params.roleId"
          class="highlight-tag"
          >{{ highlightTagText }}</span
        >
      </template>
      <template slot="authLevel" slot-scope="props">
        <span v-if="props.row.authLevel">
          {{ props.row.authLevel.displayName }}
        </span>
      </template>
      <template slot="createdAt" slot-scope="props">
        <span v-if="props.row.createdAt">
          {{ moment.unix(props.row.createdAt).format('DD/MM/YYYY') }}
        </span>
      </template>
      <template slot="lastUpdatedAt" slot-scope="props">
        <span>
          {{
            props.row.lastUpdatedAt
              ? moment.unix(props.row.lastUpdatedAt).format('DD/MM/YYYY')
              : 'N/A'
          }}
        </span>
      </template>

      <template slot="actions" slot-scope="props">
        <div
          class="d-flex flex-row align-items-center justify-content-end cursor-pointer"
          @click="goToDetails(props.row)"
        >
          <span class="view-details">{{ $t('view') }}</span>
          <i class="fas fa-chevron-right ml-2"></i>
        </div>
      </template>
    </v-client-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import listHighLightMixin from '@/mixins/listHighLightMixin';

export default {
  mixins: [listHighLightMixin],
  data() {
    return {
      loading: true,
      columns: [
        'roleType',
        'authLevel',
        'name',
        'createdAt',
        'lastUpdatedAt',
        'actions',
      ],
      options: {
        headings: {
          roleType: this.$t('role_type'),
          authLevel: this.$t('role_auth_level_short'),
          createdAt: this.$t('date_creation'),
          lastUpdatedAt: this.$t('date_last_update'),
          actions: '',
        },
        sortable: ['createdAt', 'lastUpdatedAt'],
        orderBy: {
          column: 'lastUpdatedAt',
          ascending: false,
        },
        perPage: 9999,
      },
    };
  },
  computed: {
    ...mapState({
      roles: ({ roles }) => roles.list,
    }),
  },
  created() {
    this.fetchRoles().finally(() => (this.loading = false));

    this.setBreadCrumbData({
      'list-roles': [
        {
          text: this.$t('user_management'),
          to: { name: 'list-roles' },
        },
        {
          text: this.$t('roles'),
          active: true,
        },
      ],
    });
  },

  methods: {
    ...mapActions({
      fetchRoles: 'roles/dispatchFetchRoles',
    }),
    goToDetails(role) {
      this.$store.commit('roles/SET_SELECTED_ROLE', role);
      this.$router.push({ name: 'view-role', params: { id: role.id } });
    },
  },
};
</script>
